//Variables
$xs: 575.98px;
$sm: 767.98px;
$md: 991.98px;
$lg: 1199.98px;
$xl: 1439.98px;
$x2: 1599.98px;

$c-bl: #000;
$c-wh: #fff;
$c-primary: #159a23;
$c-secondary: #1985f8;

/*Global*/

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Lato-Light.ttf');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Lato-Regular.ttf');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/Lato-Medium.ttf');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/Lato-Semibold.ttf');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/Lato-Bold.ttf');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/Lato-Black.ttf');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/SourceSansPro-SemiBold.ttf');
}

a {
    transition: all .5s ease;
    &:hover {
        text-decoration: none;
    }
}

.list-angle {
    li {
        &::before {
            content: '\f105';
            margin-right: .3rem;
            font-family: 'Font Awesome 5 Pro';
            font-weight: 400;
        }
    }
}
.container-strength {
	padding-top: 4rem;
}
.btn {
    border-radius: 30px;
    font-size: 1.125rem;
    font-weight: 600;
    border: 0;
    position: relative;
    transition: all .5s ease .5s;
    padding: 1rem 1.5rem;
    &::before {
        content: '';
        border: 3px solid transparent;
        border-right: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 100%;
        transition: all .5s ease;
        border-radius: 30px 0 0 30px;

    }
    &::after {
        content: '';
        border: 3px solid transparent;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        right: 0;
        transition: all .5s ease;
        border-radius: 0 30px 30px 0;
        border-left: 0;

    }
    &:hover {
        background-color: transparent;
        transition: all .5s ease;
        &::before {
            right: 50%;
        }
        &::after {
            left: 50%;
        }
    }
    &.btn-primary {
        &:hover {
            color: $c-primary;
            &::before,
            &::after {
                border-color: $c-primary;
            }
        }
    }
    &.btn-secondary {
        &:hover {
            color: $c-secondary;
            &::before,
            &::after {
                border-color: $c-secondary;
            }
        }
    }
}

.wow {
    visibility: hidden;
}

/*Navbar*/

.navbar-menu {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    .navbar-collapse {
        flex-grow: 0;
        .navbar-nav {
            .nav-item {
                .nav-link {
                    position: relative;
                    border-radius: 30px;
                    font-weight: 700;
                    transition: all .5s ease;
                    padding: .1rem 1rem;
                    color: $c-bl;
                    font-size: 1.125rem;
                    &::before {
                        content: '';
                        border: 2px solid transparent;
                        border-right: 0;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 100%;
                        border-radius: 30px 0 0 30px;
                        transition: all .5s ease .5s;

                    }
                    &::after {
                        content: '';
                        border: 2px solid transparent;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 100%;
                        right: 0;
                        border-radius: 0 30px 30px 0;
                        border-left: 0;
                        transition: all .5s ease .5s;

                    }
                    &:hover {
                        background-color: $c-primary;
                        color: $c-wh;
                        transition: all .5s ease .5s;
                        &::before {
                            right: 50%;
                            border-color: $c-primary;
                            transition: all .5s ease;
                        }
                        &::after {
                            left: 50%;
                            border-color: $c-primary;
                            transition: all .5s ease;
                        }
                    }

                }
                &.active {
                    .nav-link {
                        background-color: $c-primary;
                        color: $c-wh;
                    }
                }
            }
        }
    }
    .navbar-contact {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 1.125rem;
        line-height: 1.5;
        font-weight: 700;
        .far {
            color: $c-primary;
        }
        a {
            &:hover {
                color: $c-primary;
            }
        }
    }
    .navbar-social {

        a {
            margin-left: 1rem;
        }
        .fa-youtube {
            color: #a8a8a8;
            transition: all .5s ease;
            font-size: 1.4rem;
            &:hover {
                color: #f00;
            }
        }
        .fa-linkedin {
            color: #62bb6b;
            transition: all .5s ease;
            font-size: 1.6rem;
            &:hover {
                color: #0077b5
            }
        }
        .fa-facebook {
            color: #b0b0b0;
            transition: all .5s ease;
            font-size: 1.6rem;
            &:hover {
                color: #4267b2;
            }
        }
    }
    .navbar-toggler {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 0;
        z-index: 2;
        padding-right: 2px;
        padding-left: 2px;
        background-color: $c-primary;
        height: 60px;
        padding: 0 15px;
        transition: all .5s ease;
        .navbar-line {
            border-top: 2px solid rgba($c-wh, 1);
            width: 35px;
            margin: 10px 0;
            display: block;
            transition: all 0.5s ease;
        }
        &.menu-show {
            background-color: transparent;
            .navbar-line {
                border-top-color: $c-primary;
            }
            .navbar-line:nth-child(1) {
                transform: rotate(45deg);
                margin: 0;
                margin-top: 1px;
            }
            .navbar-line:nth-child(2) {
                transform: rotate(-45deg);
                margin: 0;
                margin-top: -2px;
            }
            .navbar-line:nth-child(3) {
                opacity: 0;
                margin-bottom: -2px;
                transform: rotate(90deg);
            }
        }
        &:hover {
            .navbar-line:nth-child(1) {
                transform: rotate(45deg);
                margin: 0;
                margin-top: 20px;
                margin-right: 15px;
                width: 20px;
            }
            .navbar-line:nth-child(2) {
                transform: rotate(-45deg);
                margin: 0;
                margin-top: -2px;
                margin-left: 15px;
                width: 20px;
            }
            .navbar-line:nth-child(3) {
                transform: rotate(90deg);
                margin-top: -11px;
                margin-left: 0px;
                margin-bottom: 12px;
                opacity: 1;
            }
        }
        &:hover.menu-show {
            .navbar-line:nth-child(1) {
                transform: rotate(-45deg);
                margin: 0;
                margin-top: 0px;
                margin-right: 15px;
                width: 20px;
            }
            .navbar-line:nth-child(2) {
                transform: rotate(45deg);
                margin: 0;
                margin-top: -2px;
                margin-left: 15px;
                width: 20px;
            }
            .navbar-line:nth-child(3) {
                transform: rotate(90deg);
                margin-top: 7px;
                margin-left: 0px;
                margin-bottom: 12px;
                opacity: 1;
            }
        }
    }
}

/*footer*/

footer {
    .footer-top {
        background-color: #edf0ed;
        padding-top: 2rem;
        padding-bottom: 4rem;
        ul {
            li {
                font-size: 0.94rem;
                a {
                    color: $c-bl;
                    &:hover {
                        color: $c-primary;
                    }
                }
            }
        }
        p {
            margin-bottom: 0;
            font-size: 1.125rem;
            .far {
                color: $c-primary;
            }
            a {
                color: $c-bl;
                &:hover {
                    color: $c-primary;
                }
            }
        }
        h4 {
            font-size: 1.25rem;
            font-weight: 500;
        }
    }
    .footer-bottom {
        background-color: $c-primary;
        color: $c-wh;
        font-size: 1.125rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        p {
            margin-bottom: 0;
        }
        .box-design {
            font-size: 0.75rem;
            a {
                color: $c-wh;
                font-weight: 600;
                &:hover {
                    color: #bbb;
                }
            }
        }
    }
}

/*Slider główny*/

.container-slider {
    position: relative;
    .slider-main {
        .slide {
            position: relative;
            background-color: $c-bl;
            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
                opacity: .6;
            }
            .box-slide {
                position: absolute;
                bottom: 30%;
                left: 40%;
                z-index: 1;
                color: $c-wh;
                h2 {
                    font-size: 3rem;
                    margin-bottom: 0;
                    font-weight: 300;
                    strong {
                        font-weight: 700;
                    }
                }
                .inner-slide {
                    margin-top: 2rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .btn-secondary {
                        &:hover {
                            color: $c-wh;
                            background-color: rgba($c-secondary, .8);
                            &::after,
                            &::before {
                                border-color: $c-wh;
                            }
                        }
                    }
                    .nav-slider {
                        display: flex;
                        .nav-left,
                        .nav-right {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 40px;
                            width: 40px;
                            border-radius: 10px;
                            border: 3px solid $c-wh;
                            cursor: pointer;
                            transition: all .5s ease;
                            .fal {
                                font-size: 2.5rem;
                            }
                            &:hover {
                                border-color: $c-secondary;
                                color: $c-secondary;
                            }
                        }
                        .nav-right {
                            margin-left: 1rem;
                        }
                    }
                }
            }
        }
    }
    .img-over {
        position: absolute;
        bottom: -210px;
        left: 0;
    }

}

/*partner*/

.container-partner {
    position: relative;
    .box-partner {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 5rem;
        margin-top: 3rem;
        img {
            margin-left: 2rem;
        }
        h3 {
            font-size: 1.875rem;
            font-weight: 400;
            strong {
                font-weight: 800;
            }
        }

    }
}

/*Atuty*/

.container-strength {
    .box-strength {
        background-color: $c-primary;
        color: $c-wh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transition: all .5s ease;
        cursor: pointer;
        .img-box {
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            img {
                filter: grayscale(1) brightness(5);
            }
        }
        h2 {
            font-size: 2.8rem;
            font-weight: 400;
        }
        h4 {
            font-size: 2.25rem;
            margin-top: 2rem;
            font-weight: 400;
        }
        &:hover {
            background-color: $c-wh;
            color: $c-primary;
            box-shadow: 0 0 40px rgba(0, 0, 0, .2);
            .img-box {
                img {
                    filter: grayscale(0) brightness(1);
                }
            }
        }
    }
    .row {
        & > div {
            border-right: 2px solid $c-wh;
        }
        & > div:first-child {
            .box-strength {
                border-radius: 100px 0 0 100px;
                cursor: auto;
                &:hover {
                    box-shadow: none;
                    background-color: $c-primary;
                    color: $c-wh;
                }
            }
        }
        & > div:last-child {
            .box-strength {
                border-radius: 0 100px 100px 0;
                border-right: 0;
            }
        }
    }
}

.container-strength-small {
    margin-top: 3rem;
    margin-bottom: 4rem;
    h3 {
        font-size: 3.125rem;
        font-weight: 400;
        margin-bottom: 3rem;
    }
    .box-strength {
        background-color: $c-primary;
        color: $c-wh;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        transition: all .5s ease;
        cursor: pointer;
        padding-top: 2.5rem;
        .img-box {
            height: 69px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            img {
                filter: grayscale(1) brightness(5);
                max-height: 100%;
            }
        }
        h4 {
            font-size: 1.5rem;
            margin-top: 1.5rem;
            font-weight: 400;
            text-align: center;
        }
        &:hover {
            background-color: $c-wh;
            color: $c-primary;
            box-shadow: 0 0 40px rgba(0, 0, 0, .2);
            .img-box {
                img {
                    filter: grayscale(0) brightness(1);
                }
            }
        }
    }
    .row {
        & > div {
            border-right: 2px solid $c-wh;
        }
        & > div:first-child {
            .box-strength {
                border-radius: 50px 0 0 50px;
            }
        }
        & > div:last-child {
            .box-strength {
                border-radius: 0 50px 50px 0;
                border-right: 0;
            }
        }
    }
}

/*Kategorie*/

.container-categories {
    margin-top: 4rem;
    margin-bottom: 4rem;
    .row-categories {
        .slider-categories {
            position: relative; //height: 100%;
            .slick-list {
                height: 100%;
                margin: 0;
                .slick-track {
                    height: 100%;
                    .slick-slide {
                        height: 100%;
                        & > div {
                            height: 100%;
                        }
                    }
                }
            }
            .slide {
                height: 100%;
                display: flex !important;
                justify-content: center;
                align-items: center;
                img {
                    max-height: 100%;
                    max-width: 100%;
                }
            }
            .slick-dots {
                //bottom: 0;
                width: auto;
                li {
                    button {
                        &::before {
                            width: 15px;
                            height: 15px;
                            border: 2px solid $c-primary;
                            background-color: transparent;
                        }
                    }
                    &.slick-active {
                        button {
                            &::before {
                                background-color: $c-primary;
                            }
                        }
                    }
                }
            }
        }
    }
    h3 {
        font-size: 3.375rem;
        margin-bottom: 2rem;
        font-weight: 400;
    }
    .row-left {
        .col-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
        .slider-categories {
            .slick-dots {
                right: 15px;
                left: auto;

            }
        }
    }
    .row-right {
        .col-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
        }
        h3 {
            text-align: right;
        }
        .slider-categories {
            .slick-dots {
                left: 15px;
                right: auto;

            }
        }
    }
}

/*cookies*/

.cookie-message {
    position: fixed;
    right: 20px;
    bottom: 20px;
    background-color: $c-primary;
    max-width: 430px;
    margin-left: 20px;
    z-index: 100;
    color: $c-wh;
    padding: 2rem;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.22);
    overflow: hidden;
    .btn-secondary {
        margin-top: 1rem;
        color: $c-wh;
        &:hover {
            color: $c-wh;
            &::before,
            &::after {
                border-color: $c-wh;
            }
        }
    }
}

/*Nagłówek ze zdjęciem*/

.container-header {
    padding: 0;
    .col {
        height: 450px;
        background-color: $c-bl;
    }
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        opacity: .6;

    }
}

/*partner stopka*/

.container-partner-ft {
    background: linear-gradient(to right, #f7f7f7 10%, #d3d3d3 90%);
    padding-top: 4rem;
    padding-bottom: 4rem;
    .box-partner {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        img {
            margin-left: 6rem;
        }
    }
}

/*Kontakt*/

.container-contact {
    margin-top: 4rem;
    margin-bottom: 4rem;
    h2 {
        font-weight: 400;
        font-size: 3.125rem;
        margin-bottom: 3rem;
    }
    p {
        font-size: 1.125rem;
        margin-bottom: 0;
        a {
            color: $c-bl;
            &:hover {
                color: $c-primary;
            }
        }
    }
    h4 {
        font-size: 1.25rem;
        font-weight: 700;
    }
    #map {
        height: 100%;
        width: 100%;
    }
    form {
        background-color: $c-primary;
        border-radius: 0 100px 100px 0;
        padding: 5rem 2rem;
        color: $c-wh;
        h3 {
            font-size: 2.5rem;
            margin-bottom: 2rem;
        }
        .form-control {
            border-radius: 50px;
            padding: .5rem 1.5rem;
            font-size: .94rem;
            border: 0;
            border: 3px solid $c-primary;
            margin-bottom: 1rem;
            &::placeholder {
                color: #b7b7b7;
            }
            &:focus {
                border: 3px solid $c-secondary;
            }
        }
        .form-rule-text{
            font-size: .75rem !important;
            padding-left: 1.5rem;
        }
        textarea {
            resize: none;
            height: 100%;
            margin-bottom: 0;
            border-radius: 30px !important;
        }
        .col-textarea {
            padding-bottom: 1rem;
        }
        .custom-checkbox {
            margin-bottom: 1rem;
            .custom-control-label {
                font-size: .75rem;
                &::before,
                &::after {
                    width: .7rem;
                    height: .7rem;
                }
            }
        }
        .btn-primary {
            margin-top: 2rem;
            border: 3px solid $c-wh;
            padding: .5rem 2rem;
            &:hover {
                background-color: $c-wh;
                color: $c-primary;
            }
        }
    }
}

/*O firmie*/

.container-about {
    margin: 4rem auto;
    h3 {
        font-size: 3.125rem;
        font-weight: 400;
        margin-bottom: 2rem;
    }
    p {
        font-size: 1.31rem;
        margin-bottom: 2rem;
    }
    .img-right {
        display: block;
        height: 100%;
        margin-left: 3rem;
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            border-radius: 30px;
        }
    }
}

/*Breadcrumb*/

.container-breadcrumb {
    background-color: $c-primary;
    padding-top: .5rem;
    padding-bottom: .5rem;
    .breadcrumb {
        margin-bottom: 0;
        background-color: transparent;
        padding-right: 0;
        padding-left: 0;
        .breadcrumb-item {
            color: $c-wh;
            font-weight: 700;
            font-size: .94rem;
            a {
                color: $c-wh;
                &:hover {
                    color: #ddd;
                }
            }
            &::before {
                color: $c-wh;
            }
        }
    }
}

/*Oferta*/

.container-offer {
    margin-top: 4rem;
    margin-bottom: 4rem;
    h3 {
        font-size: 2.125rem;
        font-weight: 400;
        margin-bottom: 3rem;
        margin-top: 4rem;
        line-height: 1.7rem;
        strong{
            font-size: 1.2rem;
            font-weight: normal;
        }
    }
    .box-offer {
        border: 1px solid #e3e3e3;
        padding: 1rem 2rem 2rem;
        border-radius: 20px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center; //margin-bottom: 2rem;
        justify-content: space-between;
        height: 100%;
        & > a {
            display: block;
            height: 100%;
            width: 100%;
        }
        .img-offer {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 1rem;
            border-bottom: 1px solid #e3e3e3;
            margin-bottom: 1rem;
            height: 250px;
            width: 100%;
            img {
                max-height: 100%;
            }
        }
        h5 {
            font-size: 1.5rem;
            font-weight: 400;
            color: $c-bl;
            margin-bottom: 0;
        }
        h6 {
            font-size: 1.125rem;
            font-weight: 400;
            margin-bottom: 0;
            color: $c-bl;
            &.link {
                color: $c-primary;
                font-weight: 700;
                font-size: 1rem;
                margin-top: auto;
                padding-top: 1rem;
                border-bottom: 2px solid transparent;
                transition: all .5s ease;
            }
        }
        &:hover {
            transition: all .5s ease;
            h6.link {
                border-bottom: 2px solid $c-primary;
            }
        }

    }
}

/*Widok produktu*/

.container-product {
    margin-top: 4rem;
    margin-bottom: 6rem;
    h3 {
        font-weight: 400;
        font-size: 3.125rem;
        margin-bottom: 3rem;
    }
    p {
        font-size: 1.25rem;
        margin-bottom: 2rem;
    }
    .slider-product {
        margin-bottom: 2rem;
        .slick-list {
            margin: 0;
        }
        .slide {
            border: 1px solid #e3e3e3;
            border-radius: 20px;
            position: relative;
            cursor: pointer;
            img {
                max-height: 100%;
            }
            a {
                display: flex !important;
                justify-content: center;
                align-items: center;
                padding: 2rem;
                height: 100%;
                width: 100%;
                z-index: 1;
                position: relative;
            }
            &::before {
                content: '';
                border: 1px solid transparent;
                border-right: 0;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 100%;
                border-radius: 20px 0 0 20px;
                transition: all .5s ease .5s;

            }
            &::after {
                content: '';
                border: 1px solid transparent;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 100%;
                right: 0;
                border-radius: 0 20px 20px 0;
                border-left: 0;
                transition: all .5s ease .5s;

            }
            &:hover {
                &::before {
                    right: 50%;
                    border-color: $c-primary;
                    transition: all .5s ease;
                }
                &::after {
                    left: 50%;
                    border-color: $c-primary;
                    transition: all .5s ease;
                }
            }
        }
    }
    .nav-slider-product {
        height: 140px;
        .slick-list {
            height: 100%;
            margin: 0;
            .slick-track {
                height: 100%;
            }
        }
        .slick-slide {
            height: 100%;
            & > div {
                height: 100%;
                padding: 0 .5rem;
            }
            &:first-child {
                & > div {
                    padding-left: 2px;
                }
            }
            &:last-child {
                & > div {
                    padding-right: 2px;
                }
            }
        }
        .slide {
            position: relative;
            display: flex !important;
            justify-content: center;
            align-items: center;
            padding: 1rem;
            border: 1px solid #e3e3e3;
            border-radius: 20px;
            height: 100%;
            cursor: pointer;
            img {
                max-height: 100%;
            }
            &::before {
                content: '';
                border: 1px solid transparent;
                border-right: 0;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 100%;
                border-radius: 20px 0 0 20px;
                transition: all .5s ease .5s;

            }
            &::after {
                content: '';
                border: 1px solid transparent;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 100%;
                right: 0;
                border-radius: 0 20px 20px 0;
                border-left: 0;
                transition: all .5s ease .5s;

            }
            &:hover {
                &::before {
                    right: 50%;
                    border-color: $c-primary;
                    transition: all .5s ease;
                }
                &::after {
                    left: 50%;
                    border-color: $c-primary;
                    transition: all .5s ease;
                }
            }
        }
    }
    form {
        background-color: $c-primary;
        border-radius: 20px;
        padding: 2rem 1.5rem;
        color: $c-wh;
        margin-top: 2rem;
        h5 {
            font-weight: 400;
            font-size: 1.875rem;
            margin-bottom: 0;
        }
        h6 {
            margin-bottom: 1rem;
            font-weight: 400;
            font-size: 1.25rem;
        }
        .form-control {
            border-radius: 50px;
            padding: .5rem 1.5rem;
            font-size: .94rem;
            border: 0;
            border: 3px solid $c-primary;
            margin-bottom: 1rem;
            &::placeholder {
                color: #b7b7b7;
            }
            &:focus {
                border: 3px solid $c-secondary;
            }
        }
        .custom-checkbox {
            .custom-control-label {
                font-size: .85rem;
                font-weight: 700;
                &::before,
                &::after {
                    width: .7rem;
                    height: .7rem;
                    top: 0.555rem;
                }
            }
        }
        .btn-primary {
            border: 3px solid $c-wh;
            padding: .5rem 1rem;
            font-size: 0.94rem;
            width: 100%;
            &:hover {
                background-color: $c-wh;
                color: $c-primary;
            }
        }
    }
    .nav-tabs {
        margin-top: 5rem;
        border-bottom: 0;
        .nav-item {
            margin-bottom: 0;

            .nav-link {
                color: $c-bl;
                font-size: 1.125rem;
                padding-top: 1.5rem;

                &.active {
                    border-radius: 20px 20px 0 0;
                    padding-bottom: 1rem;
                    padding-top: 1rem;
                    font-size: 1.25rem;
                    font-weight: 700;
                }
                &:hover {
                    border-color: transparent;
                    color: $c-primary;
                }
            }
        }
    }
    .tab-content {
        border: 1px solid #dee2e6;
        border-radius: 0 20px 20px 20px;
        padding: 1rem 1.5rem;
        .tab-pane {
            p {
                margin-bottom: .5rem;
                font-size: 1.125rem;
            }
        }
    }
}

/*galeria*/

.container-gallery {
    margin-top: 4rem;
    margin-bottom: 6rem;
    h3 {
        font-size: 3.125rem;
        font-weight: 400;
        margin-bottom: 3rem;
    }
    .box-projects {
        border-radius: 20px;
        overflow: hidden;
        margin-bottom: 2rem;
        a {
            display: block;
            height: 100%;
            width: 100%;
            position: relative;
        }
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;

        }
        .text-project {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: rgba($c-primary, .85);
            transition: all .5s ease;
            opacity: 0;
            h4 {
                font-size: 1.875rem;
                font-weight: 400;
                color: $c-wh;
            }
            h6 {
                font-size: 1.125rem;
                font-weight: 700;
                color: $c-wh;
            }
        }
        &:hover {
            .text-project {
                opacity: 1;
            }
        }
    }
    .box-gallery {
        border-radius: 20px;
        overflow: hidden;
        margin-bottom: 2rem;
        a {
            display: block;
            height: 100%;
            width: 100%;
            position: relative;
        }
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;

        }
        .text-gallery {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba($c-primary, .85);
            transition: all .5s ease;
            opacity: 0;
            span {
                font-size: 3rem;
                color: $c-wh;
            }
        }
        &:hover {
            .text-gallery {
                opacity: 1;
            }
        }
    }
    .btn-primary {
        margin-top: 2rem;
        background-color: $c-wh;
        color: $c-primary;
        border: 3px solid $c-primary;
        &:hover {
            background-color: $c-primary;
            color: $c-wh;
            &::before,&::after {
                border-color: $c-wh;
            }
        }
    }
}

/*RWD*/

//1600px
@media only screen and (max-width: $x2) {}

//1440px
@media only screen and (max-width: $xl) {
    .navbar-menu {
        .navbar-collapse {
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        font-size: 1rem;
                    }
                }
            }
        }
        .navbar-contact {
            font-size: 1rem;
        }
    }
    .container-strength {
        .box-strength {
            h2 {
                font-size: 2.5rem;
            }
            h4 {
                font-size: 1.8rem;
            }
        }
    }
    .container-contact {
        h2 {
            font-size: 2.8rem;
        }
    }
    .container-about {
        .img-right {
            height: 500px;
        }
    }
    .container-strength-small {
        .box-strength {
            padding-top: 1.5rem;
            h4 {
                font-size: 1.2rem;
            }
        }
    }
    .container-offer {
        .box-offer {
            .img-offer {
                height: 180px;
            }
            h5 {
                font-size: 1.2rem;
            }
            h6 {
                font-size: .9rem;
            }
        }
    }
    .container-product {
        .nav-tabs {
            .nav-item {
                .nav-link {
                    font-size: 1rem;
                    &.active {
                        font-size: 1.1rem
                    }
                }
            }
        }
        form {
            .btn-primary {
                margin-bottom: 1rem;
            }
        }
        .nav-slider-product {
            height: 110px;
        }
    }
    .container-slider .slider-main .slide .box-slide h2 {
        font-size: 2.3rem;
    }
}

//1200px
@media only screen and (max-width: $lg) {
    .navbar-menu {
        .navbar-brand {
            img {
                width: 140px;
            }
        }
        .navbar-collapse {
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        font-size: .75rem;
                    }
                }
            }
        }
        .navbar-contact {
            font-size: .75rem;
        }
    }
    footer {
        .footer-top {
            p {
                font-size: 1rem;
            }
        }
        .footer-bottom {
            p {
                font-size: 1rem;
            }
        }
    }
    .container-slider {
        .img-over {
            width: 400px;
        }
        .slider-main {
            .slide {
                .box-slide {
                    h2 {
                        font-size: 2.7rem;
                    }
                }
            }
        }
    }
    .container-partner {
        .box-partner {
            h3 {
                font-size: 1.5rem;
            }
        }
    }
    .container-strength {
        .box-strength {
            h2 {
                font-size: 2.2rem;
            }
            h4 {
                font-size: 1.2rem;
            }
            .img-box {
                height: 100px;
                img {
                    max-width: 120px;
                }
            }
        }
    }
    .container-categories {
        h3 {
            font-size: 3rem;
        }
    }
    .container-contact {
        h2 {
            font-size: 2.3rem;
        }
    }
    .container-partner-ft {
        .box-partner {
            img {
                margin-left: 2rem;
            }
        }
    }
    .container-about {
        h3 {
            font-size: 2.5rem;
        }
        p {
            font-size: 1rem;
        }
        .img-right {
            height: 400px;
        }
    }
    .container-strength-small {
        h3 {
            font-size: 2.5rem;
        }
        .box-strength {
            padding-top: 1.5rem;
            .img-box {
                height: 50px;
            }
            h4 {
                font-size: 1rem;
            }
        }
    }
    .container-offer {
        .box-offer {
            padding: 1rem 1rem 2rem;
            .img-offer {
                height: 160px;
            }
            h5 {
                font-size: 1.1rem;
            }
            h6 {
                font-size: .9rem;
            }
        }
    }
    .container-product {
        .nav-tabs {
            .nav-item {
                .nav-link {
                    font-size: 1.2rem;
                    &.active {
                        font-size: 1.3rem
                    }
                }
            }
        }
        form {
            margin-top: 4rem;
            .btn-primary {
                margin-bottom: 0;
            }
        }
        .nav-slider-product {
            height: 200px;
            margin-bottom: 2rem;
        }
    }
    .container-gallery {
        .box-projects {
            .text-project {
                h4 {
                    font-size: 1.2rem;
                }
                h6 {
                    font-size: .9rem;
                }
            }
        }
    }
}

//992px
@media only screen and (max-width: $md) {
    .navbar-menu {
        height: 60px;
        padding: 0;

        .navbar-collapse {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background-color: $c-wh;
            min-height: 100vh;
            opacity: 0;
            transition: all 0.5s ease;
            display: none;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            z-index: 1;
            overflow: scroll;
            padding-top: 5rem;
            font-size: 1.5rem;
            .navbar-nav {
                margin-top: 2rem;
                display: block;
                text-align: center;
                .nav-item {
                    margin-bottom: 1rem;
                    .nav-link {
                        margin: 0 .3rem;
                        font-size: 1.3rem;
                    }
                    .dropdown-menu {
                        text-align: center;
                        .dropdown-item {
                            font-size: 1.5rem;
                            white-space: normal;
                            line-height: 1.2;
                            padding: .5rem 1.5rem;
                        }
                    }
                }
            }
        }
        .navbar-social {
            a {
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
    .container-slider {
        .img-over {
            bottom: -145px;
            width: 300px;
        }
        .slider-main {
            .slide {
                .box-slide {
                    left: 40%;
                    h2 {
                        font-size: 2.5rem;
                    }
                }
            }
        }
    }
    .container-partner {
        .box-partner {
            h3 {
                font-size: 1.2rem;
            }
            img {
                max-width: 100px;
            }
        }
    }
    .container-strength {
        .box-strength {
            h2 {
                font-size: 1.8rem;
            }
            h4 {
                font-size: 1rem;
                margin-top: 1rem;
            }
            .img-box {
                height: 72px;
                img {
                    max-width: 90px;
                }
            }
        }
    }
    .container-categories {
        h3 {
            font-size: 2.2rem;
        }
    }
    .container-contact {
        h2 {
            font-size: 1.8rem;
        }
        #map {
            height: 400px;
        }
        form {
            border-radius: 0 0 50px 50px;
        }
    }
    .container-partner-ft {
        .box-partner {
            margin-bottom: 2rem;
        }
    }
    .container-header {
        .col {
            height: 300px;
        }
    }
    .container-about {
        h3 {
            font-size: 1.8rem;
            margin-bottom: 1rem;
        }
        p {
            margin-bottom: 1rem;
        }
        .img-right {
            height: 400px;
            margin-left: 0;
        }
    }
    .container-strength-small {
        h3 {
            margin-bottom: 2rem;
        }
        .box-strength {
            padding-top: 1rem;
            .img-box {
                height: 40px;
            }
            h4 {
                font-size: .7rem;
                margin-top: 1rem;
            }
        }
    }
    .container-offer {
        .box-offer {
            padding: 1rem 2rem 2rem;
            .img-offer {
                height: 250px;
            }
            h5 {
                font-size: 1.5rem;
            }
            h6 {
                font-size: 1rem;
            }
        }
    }
    .container-product {
        .nav-tabs {
            .nav-item {
                .nav-link {
                    font-size: .9rem;
                    &.active {
                        font-size: 1.2rem
                    }
                }
            }
        }
    }
    .container-gallery {
        .box-projects {
            .text-project {
                h4 {
                    font-size: 1.7rem;
                }
                h6 {
                    font-size: 1.1rem;
                }
            }
        }
    }
}

//768px
@media only screen and (max-width: $sm) {
    html {
        font-size: 0.85rem;
    }
    .container-slider {
        .img-over {
            bottom: -100px;
            width: 200px;
        }
        .slider-main {
            .slide {
                .box-slide {
                    left: 35%;
                    bottom: 20%;
                    h2 {
                        font-size: 2rem;
                    }
                    .inner-slide {
                        flex-direction: column;
                        align-items: flex-end;
                        margin-top: 1rem;
                        .btn {
                            margin-bottom: 1rem;
                        }
                        .nav-slider {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .container-strength {
        .box-strength {
            h2 {
                font-size: 1.5rem;
            }
            h4 {
                font-size: 1rem;
                margin-top: 1rem;
            }
            .img-box {
                height: 50px;
                img {
                    max-width: 60px;
                }
            }
        }
    }
    .container-partner {
        .box-partner {
            h3 {
                font-size: 1.1rem;
            }
        }
    }
    .container-categories {
        h3 {
            font-size: 1.8rem;
        }
        .row-categories {
            margin-bottom: 2rem;
        }
    }
    .container-about {
        .img-right {
            margin-top: 2rem;
            height: auto;
        }
    }
    .container-strength-small {
        h3 {
            margin-bottom: 2rem;
        }
        .box-strength {
            padding-top: 2rem;
            .img-box {
                height: 60px;
            }
            h4 {
                font-size: 1.2rem;
                margin-top: 2rem;
            }
        }
        .row {
            & > div {
                border-bottom: 2px solid $c-wh;
            }
            & > div:first-child,
            & > div:nth-child(4) {
                .box-strength {
                    border-radius: 50px 0 0 50px;
                }
            }
            & > div:last-child,
            & > div:nth-child(3) {
                .box-strength {
                    border-radius: 0 50px 50px 0;
                    border-right: 0;
                }
            }
        }
    }
    .container-offer {
        .box-offer {
            .img-offer {
                height: 210px;
            }
            h5 {
                font-size: 1.3rem;
            }
        }
    }
    .container-product {
        .nav-tabs {
            .nav-item {
                .nav-link {
                    font-size: .9rem;
                    &.active {
                        font-size: 1.2rem
                    }
                }
            }
        }
        .nav-slider-product {
            height: 150px;
        }
    }
}

//576px
@media only screen and (max-width: $xs) {
    .navbar-menu {
        .navbar-brand {
            padding-left: 15px;
        }
    }
    .container-slider {
        .img-over {
            bottom: -40px;
            width: 100px;
            left: 10px;
        }
        .slider-main {
            .slide {
                height: 250px;
                .box-slide {
                    left: 30%;
                    bottom: 10%;
                    h2 {
                        font-size: 1.3rem;
                    }
                    .inner-slide {
                        flex-direction: column;
                        align-items: flex-end;
                        margin-top: 1rem;
                        .btn {
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
        }
    }
    .container-partner {
        .box-partner {
            h3 {
                font-size: .9rem;
            }
            img {
                max-width: 50px;
            }
        }
    }
    .container-strength {
        .box-strength {
            padding: 2rem 0;
            h2 {
                font-size: 2rem;
            }
            h4 {
                font-size: 1.3rem;
                margin-top: 1rem;
            }
            .img-box {
                height: 64px;
                img {
                    max-width: 80px;
                }
            }
        }
        .row {
            & > div {
                border-right: 0;
                border-bottom: 2px solid $c-wh;

            }
            & > div:first-child {
                .box-strength {
                    border-radius: 100px 100px 0 0;
                    cursor: auto;
                    &:hover {
                        box-shadow: none;
                        background-color: $c-primary;
                        color: $c-wh;
                    }
                }
            }
            & > div:last-child {
                .box-strength {
                    border-radius: 0 0 100px 100px;
                    border-bottom: 0;
                }
            }
        }
    }
    .container-categories {
        h3 {
            font-size: 2.2rem;
        }
        .col-title {
            margin-bottom: 1rem;
        }
        .row-right {
            .col-title {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
            }
            h3 {
                text-align: left;
            }
            .slider-categories {
                .slick-dots {
                    left: auto;
                    right: 15px;

                }
            }
        }
    }
    .container-partner-ft {
        .box-partner {
            flex-direction: column;
            img {
                margin-left: 0;
                margin-top: 1rem;
            }
        }
    }
    .container-strength-small {
        .box-strength {
            padding-top: 2rem;
            padding-bottom: 2rem;
            .img-box {
                height: 60px;
            }
            h4 {
                font-size: 1.5rem;
                margin-top: 2rem;
            }
        }
        .row {
            & > div {
                border-bottom: 2px solid $c-wh;
                border-right: 0;
            }
            & > div:first-child,
            & > div:nth-child(4) {
                .box-strength {
                    border-radius: 0;
                }
            }
            & > div:first-child {
                .box-strength {
                    border-radius: 50px 50px 0 0;
                }
            }
            & > div:last-child,
            & > div:nth-child(3) {
                .box-strength {
                    border-radius: 0;
                }
            }
            & > div:last-child {
                .box-strength {
                    border-radius: 0 0 50px 50px;
                }
            }
        }
    }
    .container-product {
        .nav-tabs {
            .nav-item {
                .nav-link {
                    padding-top: 0;
                    padding-bottom: 0;
                    margin-bottom: 1rem;
                    font-size: 1rem;
                    border-right: 1px solid #dee2e6;
                    &.active {
                        padding-top: 0;
                        padding-bottom: 0;
                        border: 0;
                        border-color: transparent;
                        font-size: 1rem;
                        border-radius: 0;
                        border-right: 1px solid #dee2e6;
                    }
                    &:hover {
                        border-right: 1px solid #dee2e6;
                        border-radius: 0;
                    }
                }
            }
        }
        .nav-slider-product {
            height: 120px;
        }
        form {
            .btn-primary {
                margin-bottom: 1rem;
            }
        }
    }

}
